// const url = "https://node-mobileapp-hussein.mobiloitte.io"; //staging
const url = "https://node-tablebooking.mobiloitte.org";  //internal
// const url = "http://172.16.6.41:2000";
// const url = "https://node.mytawle.com";

//  const url = "http://172.16.6.123:2000";

const ApiConfig = {
  //auth flow
  loginadmin: `${url}/api/v1/admin/login`,
  // signup: `${url}/api/v1/user/register`,
  // verify: `${url}/api/v1/user/verify`,
  verifyOTP: `${url}/api/v1/admin/verifyOTP`,
  // forgot: `${url}/api/v1/user/forgot`,
  forgotPassword: `${url}/api/v1/admin/forgotPassword`,
  resetPassword: `${url}/api/v1/admin/resetPassword`,
  changePasswordAdmin: `${url}/api/v1/admin/changePassword`,
  // resend: `${url}/api/v1/user/resend`,
  resendOtp: `${url}/api/v1/admin/resendOtp`,
  // profile: `${url}/api/v1/user/profile`,
  getProfile: `${url}/api/v1/admin/getProfile`,
  // editProfile: `${url}/api/v1/user/editProfile`,
  adminEditProfile: `${url}/api/v1/admin/editProfile`,
  updateSubAdminDetails: `${url}/api/v1/admin/updateSubAdminDetails`,
  uploadImage: `${url}/api/v1/user/uploadImage`,
  uploadFile: `${url}/api/v1/user/uploadFile`,
  editAdmin: `${url}/api/v1/admin/editAdmin`,

  //userManagement Flow
  userList: `${url}/api/v1/admin/userList`,
  viewUser: `${url}/api/v1/admin/viewUser`,
  activeBlockUser: `${url}/api/v1/admin/activeBlockUser`,
  bookingList: `${url}/api/v1/booking/bookingList`,
  bookingHistory: `${url}/api/v1/admin/bookingHistory`,
  viewBookingDetails: `${url}/api/v1/admin/viewBookingDetails`,
  userRegistered: `${url}/api/v1/admin/userRegistered`,
  userwiseBooking: `${url}/api/v1/admin/userwiseBooking`,

  //SubAdmin module
  subAdminListing: `${url}/api/v1/admin/subAdminListing`,
  addSubAdmin: `${url}/api/v1/admin/addSubAdmin`,
  //Notification Management
  createBroadcastMessage: `${url}/api/v1/admin/createBroadcastMessage`,
  listNotification: `${url}/api/v1/admin/listNotification`,
  listBroadcastMessage: `${url}/api/v1/admin/listBroadcastMessage`,
  viewNotification: `${url}/api/v1/admin/viewNotification`,
  //Restaurant Management
  restaurantList: `${url}/api/v1/admin/restaurantList`,
  listRestaurants: `${url}/api/v1/restaurant/listRestaurants`,
  createRestaurant: `${url}/api/v1/restaurant/createRestaurant`,
  deleteRestaurant: `${url}/api/v1/admin/deleteRestaurant`,
  viewRestaurant: `${url}/api/v1/restaurant/viewRestaurant`,
  listSlot: `${url}/api/v1/slot/listSlot`,
  addSlot: `${url}/api/v1/slot/addSlot`,
  activeBlockRestaurant: `${url}/api/v1/admin/activeBlockRestaurant`,

  //listCategory Cuisines
  listCategory: `${url}/api/v1/category/listCategory`,

  //Payment method list api
  viewPaymentMethod: `${url}/api/v1/static/viewPaymentMethod`,

  viewDiningList: `${url}/api/v1/static/viewDiningList`,

  //Reward
  fetchReward: `${url}/api/v1/static/fetchReward`,

  //dashboard
  dashboardReport: `${url}/api/v1/admin/dashboardReport`,

  acceptEmailRequest: `${url}/api/v1/admin/acceptEmailRequest`,
  rejectEmailRequest: `${url}/api/v1/admin/rejectEmailRequest`,
  listAllEmailRequest: `${url}/api/v1/admin/listAllEmailRequest`,
  viewemailreqDetail: `${url}/api/v1/admin/viewEmailReqOfParticularUser`,
  acceptKycRequest: `${url}/api/v1/kyc/acceptKycRequest`,
  rejectKycRequest: `${url}/api/v1/kyc/rejectKycRequest`,
  kyclist: `${url}/api/v1/kyc/listAllKycRequest`,
  viewKyc: `${url}/api/v1/kyc/viewKycDetail`,
  approveOrReject: `${url}/api/v1/admin/kyc/approveOrReject`,

  createPlan: `${url}/api/v1/plan/create`,
  editPlan: `${url}/api/v1/plan/edit`,
  viewPlan: `${url}/api/v1/plan/view`,
  Planlist: `${url}/api/v1/plan/list`,

  delete: `${url}/api/v1/plan/delete/`,

  claimRewards: `${url}/api/v1/admin/getWithdrawalList`,
  viewClaimRewards: `${url}/api/v1/admin/viewWithdrawalList`,
  acceptClaimRequest: `${url}/api/v1/admin/adminApproveWithdrawal`,
  rejectClaimRequest: `${url}/api/v1/admin/adminApproveWithdrawal`,

  //static content
  staticData: `${url}/api/v1/resstatic/restaurantstaticContentList`,
  staticDatavIew: `${url}/api/v1/resstatic/resStaticContentView`,
  staticDataEdit: `${url}/api/v1/resstatic/editResStaticContent`,

  listReports: `${url}/api/v1/admin/getUsersTotalData`,
  listVoucher: `${url}/api/v1/admin/listVoucher`,
  addVoucher: `${url}/api/v1/admin/addVoucher`,
  updateReward: `${url}/api/v1/static/updateReward`,
  deleteVoucher: `${url}/api/v1/admin/deleteVoucher`,
  manageVoucher: `${url}/api/v1/admin/manageVoucher`,
};
export default ApiConfig;
